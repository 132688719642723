/* Semantic UI has these classes, however they're only applicable to*/
/* grids, containers, rows and columns.*/
/* plus, there isn't any `mobile hidden`, `X hidden` class.*/
/* this snippet is using the same class names and same approach*/
/* plus a bit more but to all elements.*/
/* see https://github.com/Semantic-Org/Semantic-UI/issues/1114*/


/* Mobile */
@media only screen and (max-width: 767px) {
    [class*="mobileHidden"],
    [class*="tablet only"]:not(.mobile),
    [class*="computer only"]:not(.mobile),
    [class*="large screen only"]:not(.mobile),
    [class*="widescreen only"]:not(.mobile),
    [class*="or lower hidden"] {
      display: none !important;
    }
  }

  /* Tablet / iPad Portrait */
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    [class*="mobile only"]:not(.tablet),
    [class*="tabletHidden"],
    [class*="computer only"]:not(.tablet),
    [class*="large screen only"]:not(.tablet),
    [class*="widescreen only"]:not(.tablet),
    [class*="or lower hidden"]:not(.mobile) {
      display: none !important;
    }
  }

  /* Computer / Desktop / iPad Landscape */
  @media only screen and (min-width: 992px) and (max-width: 1199px) {
    [class*="mobile only"]:not(.computer),
    [class*="tablet only"]:not(.computer),
    [class*="computerHidden"],
    [class*="large screen only"]:not(.computer),
    [class*="widescreen only"]:not(.computer),
    [class*="or lower hidden"]:not(.tablet):not(.mobile) {
      display: none !important;
    }
  }

  /* Large Monitor */
  @media only screen and (min-width: 1200px) and (max-width: 1919px) {
    [class*="mobile only"]:not([class*="large screen"]),
    [class*="tablet only"]:not([class*="large screen"]),
    [class*="computer only"]:not([class*="large screen"]),
    [class*="largeScreenHidden"],
    [class*="widescreen only"]:not([class*="large screen"]),
    [class*="or lower hidden"]:not(.computer):not(.tablet):not(.mobile) {
      display: none !important;
    }
  }

  /* Widescreen Monitor */
  @media only screen and (min-width: 1920px) {
    [class*="mobile only"]:not([class*="widescreen"]),
    [class*="tablet only"]:not([class*="widescreen"]),
    [class*="computer only"]:not([class*="widescreen"]),
    [class*="large screen only"]:not([class*="widescreen"]),
    [class*="widescreenHidden"],
    [class*="widescreen or lower hidden"] {
      display: none !important;
    }
  }

html {
    --scrollbarBG: #FFFFFF;
    --thumbBG: #f1f2f4;
}

.graph-container h1, .geSidebarTooltip h1,
.graph-container h2,
.graph-container h3,
.graph-container h4,
.graph-container h5,
.graph-container h6
{
    font-family: inherit;
}
div#root {
    background: -webkit-radial-gradient(circle, rgb(226, 226, 226) 7%, rgba(0,0,0,0) 0%), rgba(255,255,255,1);
    background: -moz-radial-gradient(circle, rgb(226, 226, 226) 7%, rgba(0,0,0,0) 0%), rgba(255,255,255,1);
    background: radial-gradient(circle, rgb(226, 226, 226) 7%, rgba(196, 196, 196, 0) 0%), rgba(255,255,255,1);
    background-position: 2em 2em;
    -webkit-background-origin: padding-box;
    background-origin: padding-box;
    -webkit-background-clip: border-box;
    background-clip: border-box;
    -webkit-background-size: 2em 2em;
    background-size: 2em 2em;
    font-family: "IBM Plex Sans" !important;
}

@media only screen and (max-width: 991px) {
    body {overflow: auto}
    div#root {
        min-width: 1000px;
    }

    body.workspace div#root {
        min-width: 0;
    }
}

body.workspace {
    overscroll-behavior: contain;
}

.mainContent {padding-top:25px;}

a {color: gray}
a:hover {color:#9cc8f5}

h1, h2, h3, h4, h5, h5 {
    font-family: "Quicksand" !important;
    font-weight: 500 !important;
}
h1 {text-transform: uppercase !important;}
.sub.header {font-size: 60%}

h1 div.sub.header {
    text-transform: none !important;
    font-size:1.5rem !important;
}

.ui.divider h4 {
    font-family: 'Roboto', 'Helvetica Neue', Arial, Helvetica, sans-serif !important;
}

.button {font-family: "IBM Plex Sans" !important;
    font-weight:700 !important;
    text-transform: uppercase !important
}

.ui.basic.button {
    font-weight: normal !important
}
.noborder, .ui.basic.button.noborder, .ui.basic.button.red.noborder {
    border:none!important;
    box-shadow:none !important;
}

.ui.basic.button.noborder.leftborder {
    border-left: 1px solid #ddd!important;
}

.ui.basic.button.noborder.rightborder {
    border-right: 1px solid #ddd!important;
}

.ui.basic.button.text, .ui.basic.button.text:hover, .ui.basic.button.text:focus, .ui.basic.button.text:active {
    border:none!important;
    box-shadow:none !important;
    padding-left: 2.5em !important;
    text-transform: none !important;
    font-weight: bold !important;
}

.ui.basic.button.text .icon {
    background-color: inherit;
}

.fixed.large.vertical.menu.leftbar .ui.basic.button  {
    box-shadow:none !important;
}
.ui.basic.label {
    box-shadow:none !important;
    border:none !important;
    font-weight: normal !important
}
.ui.primary.button {
    background-color: #FF9F4B !important;
}
.pointing.label {color:#FF9F4B;
    border:1px solid rgb(252, 170, 98);background:white}

.ui.multiple.dropdown > .label {
    box-shadow: none;
    background-color: #9cc8f5;
}

button.responsible-team {
    margin-right: 25px !important;
}

#topmenu.ui.menu {
background-color:#9cc8f5;  background-color:#FF9F4B;
background-color: #eee;
border-bottom: 3px solid #9cc8f5 }
#topmenu.ui.menu .item:before {width:0}
#topmenu.ui.menu .item {color:#333 !important;}
#topmenu .ui.circular.label.user {margin-left:0}
#topmenu .ui.breadcrumb a {color: inherit}
#topmenu .ui.breadcrumb .active.section {font-weight:normal; font-size:1.3rem; padding-left:0.2rem}

/* Board Overview */

.ui.event.card {
    border-radius: 2px;
    border: 1px solid #FF9F4B;
    height: 230px;
    width: 350px;
    box-shadow:3px 3px 0px 0px lightgray;
}

.ui.four.cards > .ui.event.card {
    width: 350px;
}

.ui.event.card .content:hover {
    cursor: pointer;
    color: inherit;
}

.ui.event.card a .content:hover {
    color: grey;
}
.ui.event.card.add.board button.basic {
    text-transform: uppercase;
    display:inline-block
}
.ui.event.card.add.board button.basic {
    font-size: 20px;
}

.ui.event.card.add.board {
    border: 1px dashed #9cc8f5;
    box-shadow:none;
    text-align: center;
}
.ui.event.card.add.board button {
    background-color: #9cc8f5;
    color:white;
}
.ui.event.card.add.board button.basic {
    color:#9cc8f5 !important;
}

.ui.event.card .header {
    width: 100%;
    height: 30px;
    margin: 0 !important;
    background-color: white;
    border-radius: 1px 1px 0 0;
}

.ui.event.card .content {
    padding: 0;
}

.ui.event.card .description {
    font-size: 1.6rem;
    line-height: 2.3rem;
    font-family: "Roboto";
    vertical-align: middle;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 120px;
    padding: 0 10px 10px 10px;
    margin:0 auto;
    text-transform: uppercase;
}
.ui.event.card .description span {
    text-decoration: underline solid #FF9F4B;
    text-underline-position: under;
}
.ui.event.card .content:hover .description span {
    text-decoration: underline double #FF9F4B;
    text-underline-position: auto;
}

.ui.event.card .description:before {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    top: -12px;
    width: 150px;
    margin: 0 auto;
    height: 30px;
    background: #FF9F4B;}



.ui.event.card .extra {
    text-align: left;
    display: block;
    height: auto;
    text-transform: none;
    bottom: 0;
    position: absolute;
    width: 100%;
    top: unset;
}

.ui.event.card i.share {
    color: #FF9F4B;
}

.horizontal.list > div {
    margin-bottom:10px;
}

/* Top Menu */
.ui.menu .item > .label.non-observable.user {
    margin-left: 0;
}

.ui.menu .item > .label.observable.user {
    margin-left: 0;
    cursor: pointer;
}

.observable.user:hover, .observable.user.observed {
    background-color: #0af7ff !important;
    color: #302f0b !important;
}


/* Sidebar */

.ui.large.vertical.menu.leftbar {
    width: 250px;
    border: none;
    /*transition: width 500ms linear;*/
}

.ui.large.vertical.menu.leftbar.collapsed {
    width: 100px;
    /*transition: width 500ms linear;*/
}

.leftbar {
    overflow-y: scroll;
    scrollbar-width: none; /* Firefox */
    -ms-overflow-style: none;  /* Internet Explorer 10+ */
}
.leftbar::-webkit-scrollbar { /* WebKit */
    width: 0;
    height: 0;
}

.item > .organization.switch {
    padding: 1.78571429em 1.5em 0.78571429em;;
}

.item > .organization.switch .menu {
    margin-left: -17px;
    z-index: 99999;
}

.button.disabled.organization {
    color: rgba(0, 0, 0, 0.6) !important;
    opacity: 1 !important;
}

.cody.console {
    background-color: white;
    bottom: 0;
    position: absolute;
    overflow: auto;
}

.cody.console div.console-message {
    padding: 5px 30px 5px 30px;
    border-bottom: 1px solid #f1f1f1;
    margin: 0;
    white-space: pre-wrap;
}

.cody.console div.console-message .console-avatar {
    position: absolute;
    margin-top: 4px;
}

.cody.console div.console-message .console-text {
    display: inline-block;
    margin-top: 7px;
    margin-left: 38px;
}


.cody.console div.console-message input {
    background-color: transparent;
    display: inline-block;
    width: calc(100% - 100px);
    margin-top: 4px;
    position: absolute;
    border: 0;
    outline: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
}


.cody.console .collapsible {
    position: fixed;
    height: 5px;
    width: 100%;
    background-color: #c9cacc;
    transition: height 200ms linear;
}

.cody.console .collapsible:hover {
    height: 20px;
    transition: height 200ms linear;
}

.cody.console .collapsible .collaps-handle {
    color: white;
    right: 30px;
    margin-top: 2px;
    visibility: hidden;
    position: absolute;
    transition: visibility 200ms step-start;
}

.cody.console .collapsible:hover .collaps-handle {
    visibility: visible;
    cursor: pointer;
    transition: visibility 200ms step-end;
}

.collapsebar {
    position: fixed;
    top: 0px;
    width: 10px;
    height: 100%;
    background-color: #c9cacc;
    transition: width 200ms linear;
    /*border-right: 1px solid rgba(34, 36, 38, 0.15)*/
}

.collapsebar.thin {
    width: 5px;
}

.collapsebar.collapsible:hover {
    width: 20px;
    transition: width 200ms linear;
}

.collapsebar.collapsible .collaps-handle {
    color: #fff;
    top: 76px;
    visibility: hidden;
    position: absolute;
    transition: visibility 200ms step-start;
}

.collapsebar.collapsible:hover .collaps-handle {
    color: #fff;
    top: 76px;
    visibility: visible;
    position: absolute;
    cursor: pointer;
    transition: visibility 200ms step-end;
}

.leftbar .board-list.board, .leftbar .team-list.team {
    max-width: 160px;
    display: inline-block;
}

.leftbar .board-list.board:hover, .leftbar .team-list.team:hover {
    color: #00B5AD;
}

.ui.vertical.menu .item > i.icon:first-child {float:left; margin:auto 7px auto 0}

.elements.tree a.item, .elements.tree .list a.item {
    color: #333333;
    word-break: break-word;
}

.elements.tree a.item.active {
    color: #333333;
}

.elements.tree.ui.list > a.item:hover {
    color: #333333;
}

.elements.tree a.item.active:hover {
    color: #32c0e7 !important;
}

.elements.tree .content .elements.tree, .elements.tree .content .elements.tree .content .elements.tree {
    margin-left: -20px;
}

.add-layer {
    white-space: nowrap;
    overflow-x: auto;
}

.elements.tree .context-menu {
    border-bottom: 1px solid #ddd;
}

.elements.tree i.corner {
    position: absolute;
    margin-top: 15px !important;
    float: left;
}

.elements.tree i.corner.right {
    margin-left: 7px !important;
}

.elements.tree .context-menu button,
.elements.tree .context-menu button.right.floated
{
    margin: -4px 0 0 0;
}

.elements.tree .context-menu button,
.elements.tree .add-layer button,
.elements.tree .context-menu button.right.floated {
    margin-left: -7px;
}
.elements.tree .context-menu button.ui.basic:hover {
    background: none !important;
}

.elements.tree .context-menu button.ui.basic:active {
    background: none !important;
}

.elements.tree .context-menu button.ui.basic:focus {
    background: none !important;
}

.elements.tree .context-menu button.ui.basic:hover i {
    color: #32c0e7;
}

.elements.tree a.item.hidden {
    color: rgba(40, 40, 40, 0.3) !important;
    cursor: default !important;
}

.elements.tree .list a.item.hidden {
    color: rgba(40, 40, 40, 0.3) !important;
    cursor: default !important;
}

.ui.popup .ui.menu.secondary .item:hover {
    background: none !important;
}

.ui.popup .ui.menu.secondary .item:active {
    background: none !important;
}

.ui.popup .ui.menu.secondary .item:focus {
    background: none !important;
}

.ui.popup .ui.menu.secondary .item:hover i {
    color: #32c0e7;
}

.ui.popup .ui.menu.secondary .item.delete-ele:hover i {
    color: #f31c30 !important;
}

.ui.popup .ui.menu.secondary .item.delete-ele.disabled:hover i {
    color: inherit !important;
}

i.active-layer {
    color: #32c0e7 !important;
}

i.active-layer.copy-success {
    color: #fb9f4b !important;
}

i.active-layer.copy-failed {
    color: #f31c30 !important;
}

.delete-layer button.ui.basic:hover {
    color: #f31c30 !important;
}

.ui[class*="left action"].input > input.tree.search:focus {
    border-left-color: transparent !important;
}

.search.filter.focus {
    border-color: #85B7D9 !important;
}

/* Bord Workspace */
.observing.frame {
    border-top: 4px solid;
    width: 100%;
    position: fixed;
    top: 50px;
    left: 0;
}

.observing.frame .label {
    right: 50px;
    top: -5px;
    padding: 10px;
    width: 150px;
    height: auto;
    position: absolute;
    font-size: 1rem;
    text-align: center;
    border-radius: 5px;
}

.ui.button.share {
    text-transform: capitalize !important;
    background-color: #9cc8f5;
    color: white;
}

/* Sidebar */
.logo {
    height: 100px;
}

.ui.menu a.item.logo-item:hover {
    background: inherit;
}

.copyright {
    color: gray;
}

/* Remote User Pointers */
.geRemoteMouse {
    text-align: center;
}

/* Link color is directly set on the element, see f.e. public/mxgraph/js/Actions_[version].js */
.geDiagramContainer a.boarditem:hover {
    color: rgb(108, 189, 216) !important;
    transition: color 200ms linear;
}

/* Layers Window */
div.layersWindow.mxWindow {
    position: absolute;
    z-index: 100;
}

div.layersWindow.mxWindow>img {
    z-index: 50000;
    margin-bottom: -9px;
    right: 5px !important;
}

table.layersWindow {
    background-color: rgb(224, 225, 226);
    border-spacing: 4px;
}

td.layersWindow.mxWindowTitle {
    height: 20px;
}

/* Disable geButtons */

.geButton.mxDisabled
{
    pointer-events: none;
    cursor: default;
}

.geButton.mxDisabled .geSprite
{
    opacity: 0.5;
}

/* Team Overview */

.item.admin-member .header {border-bottom:3px solid #2185D0;}
.item.admin-member .ui.avatar.image {width:2.6em; height:2.6em}
.ui.horizontal.divider {color:rgba(0, 0, 0, 0.35)}

.item > span.ui.avatar.placeholder {width:2.0em; height:2.0em; display: inline-block}
.item.admin-member span.ui.avatar.placeholder {width:2.6em; height:2.6em; display: inline-block}
.item > span.ui.avatar.placeholder + .content {
    display: inline-block;
    width: auto;
}

/* Permission User Search */
.ui.search .prompt {
    border-radius: 0.28571429rem;
    width: 100%;
}

.ui.search .ui.icon.input {
    width: 100%;
}

.ui.search > .results .result .image.avatar {
    float: left;
    display: inline-block;
    width: 2.5em;
    height: 2.5em;
}

.ui.search > .results .result .image.avatar + .content {
    margin: 0 0 0 3em;
}

/* Organization Member Dropdown*/
.ui.pointing.dropdown.organization > .menu:after {
    left: 13px !important;
}
    /* Page Dimmer below top menu */
.page.dimmer {
    z-index:99;
}
.ui.modal {
    width:50%
}


/* Effects */
.ophidden {
    opacity: 0;
}

.fadein {
    visibility: visible;
    opacity: 1;
    transition: opacity 200ms linear;
}

.fadeout {
    visibility: hidden;
    opacity: 0;
    transition: visibility 0s 200ms, opacity 200ms linear;
}

.slideup {
    max-height: 0;
    transition: max-height 200ms ease-out;
    overflow: hidden;
}

.slidedown {
    max-height: 1000px;
    transition: max-height 500ms ease-in;
}

.slideup.smooth {
    max-height: 0;
    transition: max-height 800ms ease-out;
    overflow: hidden;
}

.slidedown.smooth {
    max-height: 1000px;
    transition: max-height 800ms ease-in;
}

.ui.labels.slidedown .ui.label {margin: 0.5em 0.5em 0em 0em}

/* Cody Wizard */
.ui.inline.dropdown.cody-wizard-inline-select > .text {
    font-weight: normal;
}

.ui.inline.dropdown.cody-wizard-inline-select > .default.text {
    color: rgba(51, 51, 51, 0.6);
}

/* Monaco Editor */
.monaco-menu-container.context-view {
    display:none;
}

.monaco-editor .suggest-widget {
    width: 300px;
}

.ui.form .monaco-editor textarea.inputarea {
    width: 0;
    height: 0;
    min-width: 0;
    min-height: 0;
    padding: 0;
}

.ui.form .code.editor {
    height: 200px;
    border: 1px solid rgba(34, 36, 38, 0.15);
    padding: 20px 0;
    overflow: hidden;
    transition: height 800ms;
}

.ui.form .code.editor.focus {
    border-color: #85B7D9 !important;
}

.ui.form .code.editor.expanded {
    height: 400px;
    transition: height 800ms;
}

.ui.form .code.editor.expanded.double {
    height: 800px;
    transition: height 800ms;
}

/* Fix icon padding in list */
.ui.list .list > .item > i.icon.heart, .ui.list > .item > i.icon.heart {
    padding-right: 0.115em;
}

.ui.list .list > .item > i.icon.image, .ui.list > .item > i.icon.image {
    padding-right: 0.24em;
}


/* Blink Effect */
.blink {
    animation: blink-animation 1s steps(5, start) infinite;
    -webkit-animation: blink-animation 1s steps(5, start) infinite;
}
@keyframes blink-animation {
    0%{opacity: 0;}
    50%{opacity: .5;}
    100%{opacity: 1;}
}
@-webkit-keyframes blink-animation {
    0%{opacity: 0;}
    50%{opacity: .5;}
    100%{opacity: 1;}
}

/* Video Controls */
.ui.button.video-control:active, .ui.active.button.video-control:active,
.ui.button.video-control:focus, .ui.active.button.video-control:focus {
    background-color: #E0E1E2;
}

/* Touch Gestures */
.touch-only-pan {
    touch-action: pan-x pan-y;
}
